<template>
  <v-container fluid class="px-2">
    
  </v-container>
</template>

<script>
import LinkItem from "@/components/Stats/LinkItem";

export default {
  components: {
    LinkItem
  },

  computed: {
    items() {
      return [
        {
          title: "Meus Dados",
          icon: "mdi-account",
          to: "/profile",
          scope: ["logged"],
          isActive: true
        },
        /*{
          title: "Votações Ativas",
          icon: "mdi-vote",
          value: 0, //this.activePolls.length,
          isActive: true, //!!this.activePolls.length,
          to: "/votacoes",
          scope: ["logged"]
        },
        {
          title: "Votações Finalizadas",
          icon: "mdi-vote-outline",
          value: 0, //this.olderPolls.length,
          isActive: false, //!!this.activePolls.length,
          to: "/votacoes",
          scope: ["logged"]
        }//*/
      ];
    },

    faqs() {
      return [
        {
          icon: "mdi-help-circle-outline",
          title: "Como alterar minha senha?",
          to: "/help/faqs/faq/4",
          color: "white"
        },
        {
          icon: "mdi-help-circle-outline",
          title: "Como cadastrar uma obra?",
          to: "/help/faqs/faq/3",
          color: "white"
        },
        {
          icon: "mdi-arrow-right-bold-circle-outline",
          title: "Ver mais tópicos de ajuda",
          to: "/help/faqs",
          color: "grey lighten-4"
        }
      ];
    },
    backgroundColor() {
      return this.$vuetify.theme.dark ? "neutral darken-2" : "neutral";
    }

    /**
     * Votações ativas
     */
    // activePolls() {
    //   try {
    //     return this.$store.getters["polls/all"].filter(poll =>
    //       ["in_progress", "unstarted"].includes(poll.status)
    //     );
    //   } catch (error) {
    //     return [];
    //   }
    // },

    /**
     * Votações anteriores
     */
    // olderPolls() {
    //   try {
    //     return this.$store.getters["polls/all"].filter(poll =>
    //       ["homologated", "finished"].includes(poll.status)
    //     );
    //   } catch (error) {
    //     return [];
    //   }
    // }
  },
  methods: {
    // async refresh() {
    // this.load(true);
    // },
    // async load(force = false) {
    // try {
    //   this.$loading.show();
    //   await Promise.all([this.$store.dispatch("polls/all")]);
    //   this.$loading.hide();
    // } catch (error) {
    //   this.$loading.hide();
    //   this.$message.serverError(error);
    // }
    // }
  },
  mounted() {
    // this.load();
  }
};
</script>

<style scoped>
.background {
  height: 100%;
}
</style>
